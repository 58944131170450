import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomToolbar from './CustomToolbar';
import CustomHeader from './CustomHeader';
import CustomTimeGutterHeader from './CustomTimeGutterHeader';
import CustomEvent from './CustomEvent';
import { toLocalTimeForView } from '@utils';
import { connect, useSelector } from 'react-redux';
import { setEditEventRoomDataToReducer, setNewEventRoomDataToReducer } from 'app/redux/actions/RoomBookingActions';
import { useParams } from 'react-router-dom';
import EventModal from './EventModal';

const localizer = momentLocalizer(moment);
function isDateInWeek(startOfWeek, dateToCheck) {
  const startWeek = getPreviousSunday(startOfWeek);
  const start = moment(startWeek).startOf('day');
  const end = moment(startOfWeek).add(6, 'days').endOf('day');
  const date = moment(dateToCheck).startOf('day');

  return date.isBetween(start, end, null, '[]');
}

function getPreviousSunday(date) {
  const givenDate = moment(date).startOf('day');
  const dayOfWeek = givenDate.day();

  if (dayOfWeek === 0) {
    return givenDate;
  }

  const previousSunday = givenDate.subtract(dayOfWeek, 'days');
  return previousSunday;
}
const Calender = ({
  url,
  roomBookings,
  handleChangeRoom,
  employee,
  branch,
  room,
  organizationId,
  rooms,
  refreshDataCalendar,
  curDate,
  view,
  setView,
  isEdit,
  setNewEventRoomDataToReducer,
  setEditEventRoomDataToReducer,
}) => {
  const calendarRef = useRef(null);
  const { id: bookingId } = useParams();
  const [events, setEvents] = useState([]);
  const [resourceMap, setResourceMap] = useState([]);
  const currentUser = useSelector((state) => state.user);
  const { newEvent } = useSelector((state) => state.roomBooking);
  const [date, setDate] = useState(curDate);
  const [showModal, setShowModal] = useState({ show: isEdit, id: bookingId });
  const resourceProps =
    view === 'day'
      ? {
          resources: resourceMap,
          resourceIdAccessor: 'resourceId',
          resourceTitleAccessor: 'resourceTitle',
        }
      : {};

  useEffect(() => {
    setShowModal({ show: isEdit, id: bookingId });
  }, [isEdit, bookingId]);

  useEffect(() => {
    if (room && view === Views.WEEK) {
      setResourceMap([room].map((item) => ({ resourceId: item.id, resourceTitle: item.name })));
    } else {
      setResourceMap(rooms.map((item) => ({ resourceId: item.id, resourceTitle: item.name })));
    }
  }, [room, rooms, view]);

  useEffect(() => {
    const isInWeek = newEvent && isDateInWeek(date, newEvent.start);
    let isExistInList = false;
    let eventList = [];
    if (roomBookings?.length) {
      eventList = roomBookings
        .filter((room) => {
          return room.id !== newEvent?.id || (room.id === newEvent?.id && isInWeek);
        })
        .map((item) => {
          const dateStart = moment(item.bookingDate);
          const dateEnd = moment(item.bookingDate);
          const bookingTimeFrom = toLocalTimeForView(item.bookingTimeFrom).split(':');
          const start = dateStart.set({ hour: +bookingTimeFrom[0], minute: +bookingTimeFrom[1] }).toDate();
          const bookingTimeTo = toLocalTimeForView(item.bookingTimeTo).split(':');
          const end = dateEnd.set({ hour: +bookingTimeTo[0], minute: +bookingTimeTo[1] }).toDate();
          // check exist new Event
          if (isInWeek && isEdit && item.id === newEvent?.id) {
            isExistInList = true;
            return newEvent;
          }
          return {
            ...item,
            start,
            end,
            resourceId: item.roomId,
            employee: {
              id: item.employeeBookedId,
              name: item.employeeBookedId === currentUser.id || currentUser.isAdmin ? item.employeeName : 'Booked',
            },
            branch: {
              id: item.branchOutletId,
              name: item.branchOutletName,
            },
            room: item.roomName,
            isEdit: item?.id && item?.bookingStatus === 'Booked',
            isUpdate: item?.id && item?.bookingStatus === 'Booked',
          };
        });
    } else if (newEvent && isInWeek && isEdit) {
      isExistInList = true;
      eventList = [newEvent];
    }
    if (newEvent && isInWeek && (!isEdit || !isExistInList)) {
      eventList.push(newEvent);
    }
    setEvents(eventList);
    const event = eventList.find((item) => item.id === bookingId);
    if (isEdit) {
      setEditEventRoomDataToReducer({ url, event: { event } });
    }
  }, [roomBookings, currentUser, newEvent, date, isEdit, bookingId]);

  const refreshData = () => {
    setNewEventRoomDataToReducer(null);
    refreshDataCalendar(view, date);
  };

  const handleSelectSlot = ({ start, end, resourceId }) => {
    if (
      isEdit ||
      moment(start).minute(59).second(59).isBefore(moment()) ||
      (view === Views.DAY && !resourceId && !room)
    ) {
      return;
    }
    const newEvents = [...events].filter((item) => !item.isNew);
    const newEvent = {
      employee,
      branch,
      room,
      resourceId,
      start,
      end,
      id: new Date().getTime(),
      organizationId,
      isNew: true,
    };
    if (resourceId) {
      const newroom = rooms.find((item) => item === resourceId);
      if (newroom) {
        newEvent.room = newroom;
      }
    }
    setEvents([...newEvents, newEvent]);
  };

  const handleClearEvent = (event) => {
    const newEvents = [...events].filter((item) => item.id !== event.event.id);
    setEvents(newEvents);
  };

  const updateRoom = (room) => {
    handleChangeRoom(room);
    const newEvents = [...events].map((e) => {
      if (e.isNew || e.isEdit) {
        e.resourceId = room;
        e.room = room;
        if (e.isUpdate) {
          e.isEdit = false;
          e.isNew = true;
        }
      }
      return e;
    });
    setEvents(newEvents);
  };

  const handleChangeDate = (event) => {
    const newEvents = [...events].map((e) => {
      if ((e.isNew || e.isEdit) && e.id === event.event.id) {
        e.start = event.event.start;
        e.end = event.event.end;
        e.isEdit = event.event.isEdit;
        e.isNew = event.event.isNew;
        e.branchOutletId = event.event.branchOutletId;
        e.organizationId = event.event.organizationId;
        e.roomId = event.event.roomId;
        e.room = event.event.room;
        e.branch = event.event.branch;
      }
      return e;
    });
    setEvents(newEvents);
  };

  const resetOldEvents = (event) => {
    // const isCurrentEvent = events.some((item) => item.isNew && event.event.id === item.id);
    const isCurrentEvent = events.some((item) => item.isShow && event.event.id === item.id);
    if (isCurrentEvent) return;
    let eventList = [];
    if (roomBookings?.length) {
      eventList = roomBookings.map((item) => {
        const dateStart = moment(item.bookingDate);
        const dateEnd = moment(item.bookingDate);
        const bookingTimeFrom = toLocalTimeForView(item.bookingTimeFrom).split(':');
        const start = dateStart.set({ hour: +bookingTimeFrom[0], minute: +bookingTimeFrom[1] }).toDate();
        const bookingTimeTo = toLocalTimeForView(item.bookingTimeTo).split(':');
        const end = dateEnd.set({ hour: +bookingTimeTo[0], minute: +bookingTimeTo[1] }).toDate();

        return {
          ...item,
          start,
          end,
          resourceId: item.roomName,
          employee: {
            id: item.employeeBookedId,
            name: item.employeeBookedId === currentUser.id || currentUser.isAdmin ? item.employeeName : 'Booked',
          },
          branch: {
            id: item.branchOutletId,
            name: item.branchOutletName,
          },
          room: item.roomName,
          isEdit: item?.id && item?.bookingStatus === 'Booked',
          isUpdate: item?.id && item?.bookingStatus === 'Booked',
          isShow: item?.id === event.event.id,
        };
      });
      setEvents(eventList);
    } else {
      setEvents([]);
    }
  };

  const renderCustomEvent = (event) => {
    return (
      <CustomEvent
        refreshData={refreshData}
        rooms={rooms}
        handleChangeDate={handleChangeDate}
        view={view}
        employee={employee}
        clearEvent={() => handleClearEvent(event)}
        event={event}
        updateRoom={updateRoom}
        url={url}
        allEvents={events}
        setEvents={setEvents}
        isEdit={isEdit}
        resetOldEvents={resetOldEvents}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    );
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return '';
    },
  };

  const onDateChange = (currDate) => {
    setDate(currDate);
    //if (view === Views.WEEK && !room) return;
    const newEvent = [...events].find((item) => item.isNew);
    if (newEvent) setNewEventRoomDataToReducer(newEvent);
    if (!room) return;
    refreshDataCalendar(view, currDate);
  };

  const handleViewChange = (newView) => {
    setView(newView);
    //if (newView === Views.WEEK && !room) return;
    setNewEventRoomDataToReducer(null);
    if (!room) return;
    refreshDataCalendar(newView, date);
  };

  useLayoutEffect(() => {
    if (calendarRef.current) {
      const listGroup = calendarRef.current.querySelectorAll('.timeSlotGroup-not-allowed');
      listGroup.forEach((element) => {
        if (element?.parentNode?.parentNode && !element.parentNode.parentNode.classList.contains('rbc-time-gutter')) {
          element.parentNode.parentNode.style.cursor = 'not-allowed';
        }
      });
      const listPointerGroup = calendarRef.current.querySelectorAll('.timeSlotGroup-pointer');
      listPointerGroup.forEach((element) => {
        if (element?.parentNode?.parentNode && !element.parentNode.parentNode.classList.contains('rbc-time-gutter')) {
          element.parentNode.parentNode.style.cursor = 'pointer';
        }
      });
    }
  }, [roomBookings, date, view]);

  const CustomTimeSlotWrapper = ({ value, resource, children }) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (value <= now) {
      return <div className="timeSlotGroup-not-allowed">{children}</div>;
    } else {
      return <div className="timeSlotGroup-pointer">{children}</div>;
    }
  };

  return (
    <div className="calender" ref={calendarRef}>
      <Calendar
        localizer={localizer}
        defaultDate={date}
        defaultView={Views.WEEK}
        views={['week', 'day']}
        formats={formats}
        components={{
          toolbar: CustomToolbar,
          week: {
            header: CustomHeader,
            allDayHeader: () => null,
          },
          // day: {
          //   header: CustomHeader,
          // },
          timeGutterHeader: () => <CustomTimeGutterHeader employee={employee} />,
          event: renderCustomEvent,
          timeSlotWrapper: CustomTimeSlotWrapper,
        }}
        popup={false}
        events={events}
        selectable
        onSelectSlot={handleSelectSlot}
        onView={handleViewChange}
        {...resourceProps}
        step={60}
        timeslots={1}
        style={{ height: '100vh' }}
        onNavigate={onDateChange}
      />
      <EventModal />
    </div>
  );
};

const mapActionToProps = (dispatch) => ({
  setNewEventRoomDataToReducer: (newEvents) => dispatch(setNewEventRoomDataToReducer(newEvents)),
  setEditEventRoomDataToReducer: (newEvents) => dispatch(setEditEventRoomDataToReducer(newEvents)),
});

export default connect(undefined, mapActionToProps)(Calender);
