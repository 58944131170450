export const SET_NEW_EVENT_ROOM_DATA = 'SET_NEW_EVENT_ROOM_DATA';
export const SET_EDIT_EVENT_ROOM_DATA = 'SET_EDIT_EVENT_ROOM_DATA';

export function setNewEventRoomDataToReducer(newEventData) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_EVENT_ROOM_DATA,
      data: newEventData,
    });
  };
}

export function setEditEventRoomDataToReducer(editEventData) {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_EVENT_ROOM_DATA,
      data: editEventData,
    });
  };
}
