import { useEffect, useState } from 'react';
import axios from 'app/services/axios';

const useGetOrgSameAdmin = () => {
  const [organisations, setOrganisations] = useState([]);
  const getOrganisations = async () => {
    axios.get('/api/v1/organization/organ-for-booking').then((res) => {
      if (res.status === 200) {
        const { data: organizationList } = res.data;
        setOrganisations(organizationList);
      }
    });
  };

  useEffect(() => {
    getOrganisations();
  }, []);

  return { organisations };
};
export default useGetOrgSameAdmin;
