import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
const EventRoom = ({ event, rooms, updateRoom }) => {
  const [room, setRoom] = useState();

  useEffect(() => {
    if (event?.event && rooms) {
      const room = rooms.find((item) => item?.id === event.event?.room?.id);
      if (room) {
        setRoom(room);
      }
    }
  }, [event, rooms]);
  const handleClick = (room) => {
    setRoom(room);
    updateRoom(room);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Dropdown>
        <Dropdown.Toggle as="span" className="toggle-hidden cursor-pointer profile">
          {room?.name || ''}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          className="dropdown-menu-slottime"
          style={{ maxHeight: '300px', overflowY: 'auto', paddingTop: 0, width: '384px' }}
        >
          {rooms.map((item) => (
            <Dropdown.Item className="time-option" onClick={() => handleClick(item)}>
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default EventRoom;
