import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, SimpleCard } from '@gull';
import axios from 'app/services/axios';
import Calender from 'app/components/calender/Calender';
import { Button, Modal } from 'react-bootstrap';
import { getDateToIsoString, getLinkFile } from '@utils';
import { Views } from 'react-big-calendar';
import moment from 'moment';
import dayjs from 'dayjs';
import History from '@history';
import HeaderPage from 'app/components/HeaderPage';
import useGetOrganisations from 'app/hooks/useGetOrganisations';
import useGetOrgSameAdmin from 'app/hooks/useGetOrgSameAdmin';

const getWeekRange = (date) => {
  const startOfWeek = moment(date).startOf('week').toDate();
  const endOfWeek = moment(date).endOf('week').toDate();
  return { startOfWeek, endOfWeek };
};

const RoomBookingCalender = () => {
  const [branches, setBranches] = useState([]);
  const [rooms, setRooms] = useState([]);
  const { organisations } = useGetOrgSameAdmin();
  const [roomBookings, setRoomBookings] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [employee, setEmployee] = useState({});
  const [room, setRoom] = useState();
  const [view, setView] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const selectBoxRef = useRef(null);
  const [filter, setFilter] = useState({
    branchOutletId: '',
    roomId: '',
    organizationId: '',
  });

  const findBranch = () => branches.find((item) => item.id === filter.branchOutletId);
  const findRoom = () => rooms.find((item) => item.id === filter.roomId);
  const isExistEmpty = view === Views.WEEK && !filter.roomId;

  const getBranches = async (organizationId) => {
    const params = { organizationId };
    if (organizationId) {
      await axios.get(`/api/v1/organization/branch`, { params }).then((res) => {
        const { data, statusCode } = res?.data;
        if (statusCode === 200) {
          if (data.length) {
            const branchOutletId = data[0].id;
            getRooms(branchOutletId, organizationId, organizationId);
          } else {
            setFilter({ ...filter, branchOutletId: '', roomId: '', organizationId });
          }
          setBranches(data);
        }
      });
    } else {
      await axios.get(`/api/v1/organization/branch-all`, { params }).then((res) => {
        const { data, statusCode } = res?.data;
        if (statusCode === 200) {
          if (data.length) {
            setBranches(data);
            const branchOutletId = data[0].id;
            getRooms(branchOutletId, data[0].organizationId, '');
          } else {
            setFilter({ ...filter, branchOutletId: '', roomId: '' });
          }
          setBranches(data);
        }
      });
    }
  };

  const getRooms = async (branchId, organizationId, orgIdFilter) => {
    const branch = branches.find((item) => item.id === branchId);
    const orgId = organizationId || filter.organizationId || branch?.organizationId || '';
    await axios
      .get(`/api/v1/room/org/${orgId}?branchOutletId=` + branchId)
      .then((res) => {
        const { data, statusCode } = res?.data;
        if (statusCode === 200) {
          setRooms(data);
          if (data.length) {
            setFilter({ ...filter, branchOutletId: branchId, roomId: data[0].id, organizationId: orgIdFilter });
          } else {
            setFilter({ ...filter, branchOutletId: branchId, roomId: '', organizationId: orgIdFilter });
          }
        }
      })
      .catch(() => {
        setRooms([]);
        setFilter({ ...filter, roomId: '', branchOutletId: branchId, organizationId: orgIdFilter });
      });
  };

  const getEmployeeInfo = async () => {
    await axios.get(`/api/v1/account/profile/me`).then((res) => {
      const { data, statusCode } = res?.data;
      if (statusCode === 200) {
        setEmployee(data);
      }
    });
  };

  useEffect(() => {
    getEmployeeInfo();
    getBranches();
    // getRooms();
  }, []);

  const getCalenders = async (params) => {
    await axios.get(`/api/v1/room-booking`, { params }).then((res) => {
      const { data, statusCode } = res?.data;
      if (statusCode === 200) {
        setRoomBookings(data);
        setShowCalender(true);
      }
    });
  };

  const handleFilter = (fieldName, value) => {
    let newFilter = { ...filter };
    newFilter[fieldName] = value;
    if (fieldName === 'organizationId') {
      newFilter = { ...newFilter, branchOutletId: '', roomId: '' };
    } else if (fieldName === 'branchOutletId') {
      newFilter = { ...newFilter, roomId: '' };
    }
    setFilter({ ...newFilter });
  };

  const handleShowRoomInfo = () => {
    const roomId = selectBoxRef.current ? selectBoxRef.current.value : filter.roomId;
    const room = rooms.find(({ id }) => id === roomId);
    const params = { orgId: room?.organizationId || filter.organizationId };
    axios.get('/api/v1/room/' + roomId, { params }).then(({ data }) => {
      setRoom(data.data);
      setOpenModal(true);
    });
  };

  const handleRefreshData = (view, date) => {
    setView(view);
    setDate(date);
    const roomId = (selectBoxRef.current ? selectBoxRef.current.value : '') || filter.roomId;
    setFilter({ ...filter, roomId });
    if (view === Views.DAY) {
      getCalenders({
        isAllEmployee: true,
        employeeId: filter.employeeId,
        branchOutletId: filter.branchOutletId,
        organizationId: filter.organizationId,
        from: getDateToIsoString(moment(date).startOf('day'), '00:00'),
        to: getDateToIsoString(moment(date).endOf('day'), '23:59'),
      });
    } else {
      const dateRange = getWeekRange(date);
      getCalenders({
        isAllEmployee: true,
        employeeId: filter.employeeId,
        branchOutletId: filter.branchOutletId,
        organizationId: filter.organizationId,
        roomId,
        from: getDateToIsoString(moment(dateRange.startOfWeek).startOf('day'), '00:00'),
        to: getDateToIsoString(moment(dateRange.endOfWeek).endOf('day'), '23:59'),
      });
    }
  };

  const handleBack = () => {
    History.push('/staff-room-booking');
  };

  const bookRoom = () => {
    setShowCalender(true);
    handleRefreshData(view, date);
  };

  const updateRoom = (room) => {
    if (selectBoxRef.current) {
      selectBoxRef.current.value = room.id;
    }
  };

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Room Booking', path: '/staff-room-booking' },
          {
            name: 'New Room Booking',
          },
        ]}
      />
      <HeaderPage title="New Room Booking" />
      <SimpleCard>
        <div className="room-booking-calender-filter">
          <div className="row">
            <div className="col-12 col-xl-3">
              <div className="form-group mb-4">
                <label htmlFor="organizationId">Organisation*</label>
                <select
                  id="organizationId"
                  className="form-control"
                  value={filter.organizationId}
                  name="organizationId"
                  onChange={(e) => {
                    const organizationId = e.currentTarget.value;
                    setRooms([]);
                    getBranches(organizationId);
                    handleFilter('organizationId', organizationId);
                  }}
                >
                  <option value="">All</option>
                  {organisations.map((org) => (
                    <option value={org.id} key={org.id}>
                      {org.organizationName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-xl-3">
              <div className="form-group mb-4">
                <label htmlFor="branchOutletId">Branch/Outlet*</label>
                <select
                  id="branchOutletId"
                  className="form-control"
                  value={filter.branchOutletId}
                  name="branchOutletId"
                  onChange={(e) => {
                    const branchId = e.currentTarget.value;
                    if (branchId) getRooms(branchId);
                    handleFilter('branchOutletId', branchId);
                  }}
                >
                  {branches.map((branch) => (
                    <option value={branch.id} key={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-xl-3">
              <div className="form-group mb-4">
                <label htmlFor="roomId">Room*</label>
                <div className="d-flex">
                  <select
                    id="roomId"
                    className="form-control"
                    name="roomId"
                    disabled={view === Views.DAY}
                    value={filter.roomId}
                    ref={selectBoxRef}
                    onChange={(e) => view === Views.WEEK && handleFilter('roomId', e.currentTarget.value)}
                  >
                    {rooms.map((room) => (
                      <option value={room.id} key={room.id}>
                        {room.name}
                      </option>
                    ))}
                  </select>
                  {view !== Views.DAY && selectBoxRef.current && selectBoxRef.current.value && (
                    <img
                      onClick={handleShowRoomInfo}
                      style={{ marginLeft: '20px', cursor: 'pointer' }}
                      alt="information"
                      src="/assets/images/icons/room-info.svg"
                    />
                  )}
                </div>
              </div>
            </div>

            {showCalender && (
              <div className="col-12 col-xl-3">
                <div className="mt-24 d-flex">
                  <Button
                    className="btn-rounded"
                    onClick={bookRoom}
                    style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
                    disabled={isExistEmpty}
                  >
                    Search
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {showCalender && (
            <Calender
              view={view}
              setView={setView}
              curDate={date}
              refreshDataCalendar={handleRefreshData}
              rooms={rooms}
              employee={employee}
              organizationId={filter.organizationId}
              roomBookings={roomBookings}
              branch={findBranch()}
              room={findRoom()}
              handleChangeRoom={updateRoom}
              url={'/staff-room-booking'}
            />
          )}
        </div>
      </SimpleCard>
      <div
        className="button-wrapper d-flex justify-content-end"
        style={{ marginTop: '40px', marginBottom: '10px', gap: '40px' }}
      >
        <Button
          className="btn-rounded btn-outline-primary"
          onClick={handleBack}
          style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
        >
          Back
        </Button>
        {!showCalender && (
          <Button
            className="btn-rounded"
            onClick={bookRoom}
            style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
            disabled={isExistEmpty}
          >
            Book Room
          </Button>
        )}
      </div>
      <Modal show={openModal}>
        <div style={{ padding: '20px' }}>
          <img alt="room" src={getLinkFile(room?.image)} />
          <div style={{ marginTop: '20px', marginBottom: '40px', fontSize: '20px', fontWeight: 600 }}>{room?.name}</div>
          <div className="d-flex" style={{ gap: '10px' }}>
            {room?.amenitySelected.map((item) => (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: 'rgba(236, 255, 236, 1)',
                  border: '1px solid rgba(99, 205, 98, 1)',
                  borderRadius: '5px',
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div style={{ marginTop: '40px' }} className="d-flex justify-content-end">
            <Button
              onClick={() => setOpenModal(false)}
              style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RoomBookingCalender;
