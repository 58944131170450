export * from './country-national-list';
export * from './message';
export const paginationOptions = (totalSize, onPageChange, paginationTotalRenderer) => ({
  // custom: true,
  paginationSize: 5,
  pageStartIndex: 1,
  firstPageText: 'First',
  prePageText: 'Back',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: true,
  totalSize,
  onPageChange,
  paginationTotalRenderer,
  hideSizePerPage: true,
});

export const paginationOptionCustom = (totalSize, onPageChange, paginationTotalRenderer) => ({
  // custom: true,
  paginationSize: totalSize,
  pageStartIndex: 1,
  firstPageText: 'First',
  prePageText: 'Back',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: false,
  totalSize: totalSize,
  onPageChange,
  paginationTotalRenderer,
  sizePerPageList: [
    {
      text: '100',
      value: 100,
    },
  ],
  hideSizePerPage: true,
});

export const PHONE_REGEX = /^[0-9\s\+]{10,14}$/;
export const PHONE_2_REGEX = /^[0-9\s\+]{0,50}$/;

export const QuestionTypes = [
  {
    value: 'ShortAnswer',
    label: 'Short Answer',
  },
  {
    value: 'LongAnswer',
    label: 'Long Answer',
  },
];

export const BUSINESS_TYPE = [
  { value: 'UEN', label: 'UEN - Local Company Registration Number Issue by ACRA' },
  { value: 'UEN-B', label: 'UEN (B) - Company Registration Number Issue by ACRA' },
  {
    value: 'UEN-OTHER',
    label: 'UEN Others - Unique Entity Number Others (e.g. Foreign Company Registration Number, LLP etc.)',
  },
  { value: 'ASGB', label: 'ASGD - Tax Reference Number assigned by IRAS' },
  { value: 'INCOME', label: 'Income Tax Reference Number assigned by IRAS' },
];

export const INDUSTRIES = [
  'Advertising & Marketing',
  'Aerospace',
  'Agriculture',
  'Computer & IT',
  'Construction',
  'Education',
  'Energy',
  'Arts & Entertainment',
  'Banking & Finance',
  'Insurance',
  'Food & Beverage',
  'Medical & Healthcare',
  'Tourism & Hospitality',
  'Manufacturing',
  'Media & News',
  'Mining & Quarrying',
  'Pharmaceutical',
  'Information & Communications',
  'Transportations & Storage',
  'Retail Trade',
  'Wholesale Trade',
  'Utilities',
  'Professional Services',
  'Logistics',
  'Charities & NGOs',
  'Civil Service',
  'Consultancy',
  'Recruitment',
  'Real Estate',
  'Social Services',
  'Electrical / Electronics',
  'Maritime',
  'Research & Development',
  'Biomedical & Biotechnology',
  'Data Science & Analytics',
  'Public Services',
  'Chemicals',
  'E-commerce',
  'Others',
];

export const InterviewStatusList = [
  {
    value: 'New',
    label: 'New',
  },
  {
    value: 'In Review',
    label: 'In Review',
  },
  {
    value: 'Phone Screen',
    label: 'Phone Screen',
  },
  {
    value: 'Assessment',
    label: 'Assessment',
  },
  {
    value: 'Interview',
    label: 'Interview',
  },
  {
    value: 'Background',
    label: 'Background',
  },
  {
    value: 'Offered',
    label: 'Offered',
  },
  {
    value: 'Hired',
    label: 'Hired',
  },
  {
    value: 'Disqualified',
    label: 'Disqualified',
  },
];

export const NationalList = [
  {
    id: 'Afghan',
    label: 'Afghan',
  },
  {
    id: 'Albanian',
    label: 'Albanian',
  },
  {
    id: 'Algerian',
    label: 'Algerian',
  },
  {
    id: 'American',
    label: 'American',
  },
  {
    id: 'Andorran',
    label: 'Andorran',
  },
  {
    id: 'Angolan',
    label: 'Angolan',
  },
  {
    id: 'Antiguan',
    label: 'Antiguan',
  },
  {
    id: 'Argentine',
    label: 'Argentine',
  },
  {
    id: 'Armenian',
    label: 'Armenian',
  },
  {
    id: 'Australian',
    label: 'Australian',
  },
  {
    id: 'Austrian',
    label: 'Austrian',
  },
  {
    id: 'Azerbaijani',
    label: 'Azerbaijani',
  },
  {
    id: 'Bahamian',
    label: 'Bahamian',
  },
  {
    id: 'Bahraini',
    label: 'Bahraini',
  },
  {
    id: 'Bangladeshi',
    label: 'Bangladeshi',
  },
  {
    id: 'Barbadian',
    label: 'Barbadian',
  },
  {
    id: 'Belarusian',
    label: 'Belarusian',
  },
  {
    id: 'Belgian',
    label: 'Belgian',
  },
  {
    id: 'Belizean',
    label: 'Belizean',
  },
  {
    id: 'Beninese',
    label: 'Beninese',
  },
  {
    id: 'Bhutanese',
    label: 'Bhutanese',
  },
  {
    id: 'Bolivian',
    label: 'Bolivian',
  },
  {
    id: 'Bosnian',
    label: 'Bosnian',
  },
  {
    id: 'Botswanan',
    label: 'Botswanan',
  },
  {
    id: 'Brazilian',
    label: 'Brazilian',
  },
  {
    id: 'British',
    label: 'British',
  },
  {
    id: 'Bruneian',
    label: 'Bruneian',
  },
  {
    id: 'Bulgarian',
    label: 'Bulgarian',
  },
  {
    id: 'Burkinabe',
    label: 'Burkinabe',
  },
  {
    id: 'Burmese',
    label: 'Burmese',
  },
  {
    id: 'Burundian',
    label: 'Burundian',
  },
  {
    id: 'Cambodian',
    label: 'Cambodian',
  },
  {
    id: 'Cameroonian',
    label: 'Cameroonian',
  },
  {
    id: 'Canadian',
    label: 'Canadian',
  },
  {
    id: 'Cape Verdean',
    label: 'Cape Verdean',
  },
  {
    id: 'Central African',
    label: 'Central African',
  },
  {
    id: 'Chadian',
    label: 'Chadian',
  },
  {
    id: 'Chilean',
    label: 'Chilean',
  },
  {
    id: 'Chinese',
    label: 'Chinese',
  },
  {
    id: 'Colombian',
    label: 'Colombian',
  },
  {
    id: 'Comorian',
    label: 'Comorian',
  },
  {
    id: 'Congolese',
    label: 'Congolese',
  },
  {
    id: 'Costa Rican',
    label: 'Costa Rican',
  },
  {
    id: 'Croatian',
    label: 'Croatian',
  },
  {
    id: 'Cuban',
    label: 'Cuban',
  },
  {
    id: 'Cypriot',
    label: 'Cypriot',
  },
  {
    id: 'Czech',
    label: 'Czech',
  },
  {
    id: 'Danish',
    label: 'Danish',
  },
  {
    id: 'Djiboutian',
    label: 'Djiboutian',
  },
  {
    id: 'Dominican',
    label: 'Dominican',
  },
  {
    id: 'Dutch',
    label: 'Dutch',
  },
  {
    id: 'East Timorese',
    label: 'East Timorese',
  },
  {
    id: 'Ecuadorean',
    label: 'Ecuadorean',
  },
  {
    id: 'Egyptian',
    label: 'Egyptian',
  },
  {
    id: 'Emirian',
    label: 'Emirian',
  },
  {
    id: 'Equatorial Guinean',
    label: 'Equatorial Guinean',
  },
  {
    id: 'Eritrean',
    label: 'Eritrean',
  },
  {
    id: 'Estonian',
    label: 'Estonian',
  },
  {
    id: 'Ethiopian',
    label: 'Ethiopian',
  },
  {
    id: 'Fijian',
    label: 'Fijian',
  },
  {
    id: 'Filipino',
    label: 'Filipino',
  },
  {
    id: 'Finnish',
    label: 'Finnish',
  },
  {
    id: 'French',
    label: 'French',
  },
  {
    id: 'Gabonese',
    label: 'Gabonese',
  },
  {
    id: 'Gambian',
    label: 'Gambian',
  },
  {
    id: 'Georgian',
    label: 'Georgian',
  },
  {
    id: 'German',
    label: 'German',
  },
  {
    id: 'Ghanaian',
    label: 'Ghanaian',
  },
  {
    id: 'Greek',
    label: 'Greek',
  },
  {
    id: 'Grenadian',
    label: 'Grenadian',
  },
  {
    id: 'Guatemalan',
    label: 'Guatemalan',
  },
  {
    id: 'Guinean',
    label: 'Guinean',
  },
  {
    id: 'Guinea-Bissauan',
    label: 'Guinea-Bissauan',
  },
  {
    id: 'Guyanese',
    label: 'Guyanese',
  },
  {
    id: 'Haitian',
    label: 'Haitian',
  },
  {
    id: 'Herzegovinian',
    label: 'Herzegovinian',
  },
  {
    id: 'Honduran',
    label: 'Honduran',
  },
  {
    id: 'Hungarian',
    label: 'Hungarian',
  },
  {
    id: 'Icelander',
    label: 'Icelander',
  },
  {
    id: 'Indian',
    label: 'Indian',
  },
  {
    id: 'Indonesian',
    label: 'Indonesian',
  },
  {
    id: 'Iranian',
    label: 'Iranian',
  },
  {
    id: 'Iraqi',
    label: 'Iraqi',
  },
  {
    id: 'Irish',
    label: 'Irish',
  },
  {
    id: 'Israeli',
    label: 'Israeli',
  },
  {
    id: 'Italian',
    label: 'Italian',
  },
  {
    id: 'Ivorian',
    label: 'Ivorian',
  },
  {
    id: 'Jamaican',
    label: 'Jamaican',
  },
  {
    id: 'Japanese',
    label: 'Japanese',
  },
  {
    id: 'Jordanian',
    label: 'Jordanian',
  },
  {
    id: 'Kazakhstani',
    label: 'Kazakhstani',
  },
  {
    id: 'Kenyan',
    label: 'Kenyan',
  },
  {
    id: 'Kittian and Nevisian',
    label: 'Kittian and Nevisian',
  },
  {
    id: 'Kuwaiti',
    label: 'Kuwaiti',
  },
  {
    id: 'Kyrgyz',
    label: 'Kyrgyz',
  },
  {
    id: 'Laotian',
    label: 'Laotian',
  },
  {
    id: 'Latvian',
    label: 'Latvian',
  },
  {
    id: 'Lebanese',
    label: 'Lebanese',
  },
  {
    id: 'Liberian',
    label: 'Liberian',
  },
  {
    id: 'Libyan',
    label: 'Libyan',
  },
  {
    id: 'Liechtensteiner',
    label: 'Liechtensteiner',
  },
  {
    id: 'Lithuanian',
    label: 'Lithuanian',
  },
  {
    id: 'Luxembourger',
    label: 'Luxembourger',
  },
  {
    id: 'Macedonian',
    label: 'Macedonian',
  },
  {
    id: 'Malagasy',
    label: 'Malagasy',
  },
  {
    id: 'Malawian',
    label: 'Malawian',
  },
  {
    id: 'Malaysian',
    label: 'Malaysian',
  },
  {
    id: 'Maldivan',
    label: 'Maldivan',
  },
  {
    id: 'Malian',
    label: 'Malian',
  },
  {
    id: 'Maltese',
    label: 'Maltese',
  },
  {
    id: 'Marshallese',
    label: 'Marshallese',
  },
  {
    id: 'Mauritanian',
    label: 'Mauritanian',
  },
  {
    id: 'Mauritian',
    label: 'Mauritian',
  },
  {
    id: 'Mexican',
    label: 'Mexican',
  },
  {
    id: 'Micronesian',
    label: 'Micronesian',
  },
  {
    id: 'Moldovan',
    label: 'Moldovan',
  },
  {
    id: 'Monacan',
    label: 'Monacan',
  },
  {
    id: 'Mongolian',
    label: 'Mongolian',
  },
  {
    id: 'Moroccan',
    label: 'Moroccan',
  },
  {
    id: 'Mosotho',
    label: 'Mosotho',
  },
  {
    id: 'Motswana',
    label: 'Motswana',
  },
  {
    id: 'Mozambican',
    label: 'Mozambican',
  },
  {
    id: 'Namibian',
    label: 'Namibian',
  },
  {
    id: 'Nauruan',
    label: 'Nauruan',
  },
  {
    id: 'Nepalese',
    label: 'Nepalese',
  },
  {
    id: 'New Zealander',
    label: 'New Zealander',
  },
  {
    id: 'Nicaraguan',
    label: 'Nicaraguan',
  },
  {
    id: 'Nigerian',
    label: 'Nigerian',
  },
  {
    id: 'Nigerien',
    label: 'Nigerien',
  },
  {
    id: 'North Korean',
    label: 'North Korean',
  },
  {
    id: 'Northern Irish',
    label: 'Northern Irish',
  },
  {
    id: 'Norwegian',
    label: 'Norwegian',
  },
  {
    id: 'Omani',
    label: 'Omani',
  },
  {
    id: 'Pakistani',
    label: 'Pakistani',
  },
  {
    id: 'Palauan',
    label: 'Palauan',
  },
  {
    id: 'Palestinian',
    label: 'Palestinian',
  },
  {
    id: 'Panamanian',
    label: 'Panamanian',
  },
  {
    id: 'Papua New Guinean',
    label: 'Papua New Guinean',
  },
  {
    id: 'Paraguayan',
    label: 'Paraguayan',
  },
  {
    id: 'Peruvian',
    label: 'Peruvian',
  },
  {
    id: 'Polish',
    label: 'Polish',
  },
  {
    id: 'Portuguese',
    label: 'Portuguese',
  },
  {
    id: 'Qatari',
    label: 'Qatari',
  },
  {
    id: 'Romanian',
    label: 'Romanian',
  },
  {
    id: 'Russian',
    label: 'Russian',
  },
  {
    id: 'Rwandan',
    label: 'Rwandan',
  },
  {
    id: 'Saint Lucian',
    label: 'Saint Lucian',
  },
  {
    id: 'Salvadoran',
    label: 'Salvadoran',
  },
  {
    id: 'Samoan',
    label: 'Samoan',
  },
  {
    id: 'San Marinese',
    label: 'San Marinese',
  },
  {
    id: 'Sao Tomean',
    label: 'Sao Tomean',
  },
  {
    id: 'Saudi',
    label: 'Saudi',
  },
  {
    id: 'Scottish',
    label: 'Scottish',
  },
  {
    id: 'Senegalese',
    label: 'Senegalese',
  },
  {
    id: 'Serbian',
    label: 'Serbian',
  },
  {
    id: 'Seychellois',
    label: 'Seychellois',
  },
  {
    id: 'Sierra Leonean',
    label: 'Sierra Leonean',
  },
  {
    id: 'Singapore',
    label: 'Singapore',
  },
  {
    id: 'Slovakian',
    label: 'Slovakian',
  },
  {
    id: 'Slovenian',
    label: 'Slovenian',
  },
  {
    id: 'Solomon Islander',
    label: 'Solomon Islander',
  },
  {
    id: 'Somali',
    label: 'Somali',
  },
  {
    id: 'South African',
    label: 'South African',
  },
  {
    id: 'South Korean',
    label: 'South Korean',
  },
  {
    id: 'Spanish',
    label: 'Spanish',
  },
  {
    id: 'Sri Lankan',
    label: 'Sri Lankan',
  },
  {
    id: 'Sudanese',
    label: 'Sudanese',
  },
  {
    id: 'Surinamer',
    label: 'Surinamer',
  },
  {
    id: 'Swazi',
    label: 'Swazi',
  },
  {
    id: 'Swedish',
    label: 'Swedish',
  },
  {
    id: 'Swiss',
    label: 'Swiss',
  },
  {
    id: 'Syrian',
    label: 'Syrian',
  },
  {
    id: 'Taiwanese',
    label: 'Taiwanese',
  },
  {
    id: 'Tajik',
    label: 'Tajik',
  },
  {
    id: 'Tanzanian',
    label: 'Tanzanian',
  },
  {
    id: 'Thai',
    label: 'Thai',
  },
  {
    id: 'Togolese',
    label: 'Togolese',
  },
  {
    id: 'Tongan',
    label: 'Tongan',
  },
  {
    id: 'Trinidadian or Tobagonian',
    label: 'Trinidadian or Tobagonian',
  },
  {
    id: 'Tunisian',
    label: 'Tunisian',
  },
  {
    id: 'Turkish',
    label: 'Turkish',
  },
  {
    id: 'Tuvaluan',
    label: 'Tuvaluan',
  },
  {
    id: 'Ugandan',
    label: 'Ugandan',
  },
  {
    id: 'Ukrainian',
    label: 'Ukrainian',
  },
  {
    id: 'Uruguayan',
    label: 'Uruguayan',
  },
  {
    id: 'Uzbekistani',
    label: 'Uzbekistani',
  },
  {
    id: 'Venezuelan',
    label: 'Venezuelan',
  },
  {
    id: 'Vietnam',
    label: 'Vietnam',
  },
  {
    id: 'Welsh',
    label: 'Welsh',
  },
  {
    id: 'Yemenite',
    label: 'Yemenite',
  },
  {
    id: 'Zambian',
    label: 'Zambian',
  },
  {
    id: 'Zimbabwean',
    label: 'Zimbabwean',
  },
];

export const RaceList = [
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'Malay',
    label: 'Malay',
  },
  {
    value: 'Indian',
    label: 'Indian',
  },
  {
    value: 'Eurasian',
    label: 'Eurasian',
  },
  {
    value: 'BumiputeraSabah',
    label: 'Bumiputera Sabah',
  },
  {
    value: 'Thai',
    label: 'Thai',
  },
  {
    value: 'Filipino',
    label: 'Filipino',
  },
  {
    value: 'Javanese',
    label: 'Javanese',
  },
  {
    value: 'Sudanese',
    label: 'Sudanese',
  },
  {
    value: 'Vietnamese',
    label: 'Vietnamese',
  },
  {
    value: 'Caucasian',
    label: 'Caucasian',
  },
  {
    value: 'Korean',
    label: 'Korean',
  },
  {
    value: 'Japanese',
    label: 'Japanese',
  },
  {
    value: 'Others',
    label: 'Others',
  },
];

export const RACE_SHOW = ['Chinese', 'Malay', 'Indian', 'Eurasian'];
export const RACIAL_DONATION_FUND = {
  Chinese: 'Chinese Development Assistance (CDAC) Fund ',
  Malay: 'Mosque Building and Mendaki Fund (MBMF)',
  Indian: 'Singapore Indian Development Association (SINDA) Fund',
  Eurasian: 'Eurasian Community Fund (ECF)',
};

export const IdentityTypes = [
  {
    value: 'NRIC',
    label: 'NRIC',
  },
  {
    value: 'FIN',
    label: 'FIN',
  },
  {
    value: 'ImmigrationFileRefNo',
    label: 'Immigration File Ref no.',
  },
  {
    value: 'WorkPermitNo',
    label: 'Work Permit No.',
  },
  {
    value: 'MalaysianIC',
    label: 'Malaysian IC (for non-resident director and seaman only)',
  },
  {
    value: 'PassportNo1',
    label: 'Passport No. (for non-resident director and seaman only)',
  },
  {
    value: 'PassportNo2',
    label: 'Passport No. (for non-reisdent employee only)',
  },
];

export const Genders = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Others',
    label: 'Others',
  },
];

export const ReligionList = [
  {
    value: 'Buddhist',
    label: 'Buddhist',
  },
  {
    value: 'Christian',
    label: 'Christian',
  },
  {
    value: 'Catholicism',
    label: 'Catholicism',
  },
  {
    value: 'Hindu',
    label: 'Hindu',
  },
  {
    value: 'Muslim',
    label: 'Muslim',
  },
  {
    value: 'Sikh',
    label: 'Sikh',
  },
  {
    value: 'Taoist',
    label: 'Taoist',
  },
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Others',
    label: 'Others',
  },
];

export const MaritalStatusList = [
  {
    value: 'Single',
    label: 'Single',
  },
  {
    value: 'Married',
    label: 'Married',
  },
  {
    value: 'Divorce',
    label: 'Divorce',
  },
  {
    value: 'Widowed',
    label: 'Widowed',
  },
];

export const SampleOptions = [
  {
    value: 'Option 1',
    label: 'Option 1',
  },
  {
    value: 'Option 2',
    label: 'Option 2',
  },
  {
    value: 'Option 3',
    label: 'Option 3',
  },
];

export const EmploymentTypeOptions = [
  {
    value: 'fullTime',
    label: 'Full Time',
  },
  {
    value: 'partTime',
    label: 'Part Time',
  },
  {
    value: 'contract',
    label: 'Contract',
  },
  {
    value: 'probation',
    label: 'Probation',
  },

  {
    value: 'internship',
    label: 'Internship',
  },
  {
    value: 'freelance',
    label: 'Freelance',
  },
];

export const PaymentMethodOptions = [
  {
    value: 'cash',
    label: 'Cash',
  },
  {
    value: 'cheque',
    label: 'Cheque',
  },
  {
    value: 'giro',
    label: 'GIRO',
  },
  {
    value: 'others',
    label: 'Others',
  },
];

export const BankNameOptions = [
  {
    value: 'dbs/posb',
    label: 'DBS/POSB',
  },
  {
    value: 'uob',
    label: 'UOB',
  },
  {
    value: 'ocbc',
    label: 'OCBC',
  },
  {
    value: 'standardCharteredBank',
    label: 'Standard Chartered Bank',
  },
  {
    value: 'hsnb',
    label: 'HSNB',
  },
  {
    value: 'maybank',
    label: 'Maybank',
  },
  {
    value: 'hsbc',
    label: 'HSBC',
  },
  {
    value: 'maybank',
    label: 'Maybank',
  },
  {
    value: 'cimb',
    label: 'CIMB',
  },
  {
    value: 'citibank',
    label: 'Citibank',
  },
  {
    value: 'fareasternbank',
    label: 'Far Eastern Bank',
  },
  {
    value: 'australia&newzealand',
    label: 'Australia & New Zealand Banking Group Limited',
  },
  {
    value: 'bangkokbankpubliccompanylimited',
    label: 'Bangkok Bank Public Company Limited',
  },
  {
    value: 'bankofchinalimited',
    label: 'Bank of China Limited',
  },
  {
    value: 'bankofeasasialimited',
    label: 'Bank of East Asia Limited',
  },
  {
    value: 'bankofindia',
    label: 'Bank of India',
  },
  {
    value: 'bankoftokyo-mitsubishi',
    label: 'Bank of Tokyo-Mitsubishi UFJ, LTD',
  },
  {
    value: 'bnpparibas',
    label: 'BNP Paribas',
  },
  {
    value: 'creditagricolecorporateandinvestmentbank',
    label: 'Credit Agricole Corporate and Investment Bank',
  },
  {
    value: 'hlbank',
    label: 'HL Bank',
  },
  {
    value: 'icicbanklimited',
    label: 'ICIC Bank Limited',
  },
  {
    value: 'indianbank',
    label: 'Indian Bank',
  },
  {
    value: 'indianoverseasbank',
    label: 'Indian Overseas Bank',
  },
  {
    value: 'industrialandcommercialbankofchinalimited',
    label: 'Industrial and Commercial Bank of China Limited',
  },
  {
    value: 'jpmorganchasebank',
    label: 'JPMorgan Chase Bank, N.A.',
  },
  {
    value: 'mizuhocorporatebankltd',
    label: 'Mizuho Corporate Bank LTD',
  },
  {
    value: 'ptbanknegaraindoensia(persero)tbk',
    label: 'PT Bank Negara Indoensia (Persero) TBK',
  },
  {
    value: 'rhbbankberhad',
    label: 'RHB Bank Berhad',
  },
  {
    value: 'statebankofindia',
    label: 'State Bank of India',
  },
  {
    value: 'sumitomomitsuibankingcorporation',
    label: 'Sumitomo Mitsui Banking Corporation',
  },
  {
    value: 'ucobank',
    label: 'UCO Bank',
  },
  // {
  //   value: 'others',
  //   label: 'Others',
  // },
];

export const IRBACodes = [
  {
    value: 'Insurance',
    label: 'Insurance',
  },
  {
    value: 'GrossSalary',
    label: 'Gross Salary',
  },
  {
    value: 'Bonus',
    label: 'Bonus',
  },
  {
    value: 'DirectorFee',
    label: 'Director’s Fee',
  },
  {
    value: 'GrossComission',
    label: 'Gross Comission',
  },
  {
    value: 'Pension',
    label: 'Pension',
  },
  {
    value: 'TransportAllowance',
    label: 'Transport Allowance',
  },
  {
    value: 'EntertainmentAllowance',
    label: 'Entertainment Allowance',
  },
  {
    value: 'OtherAllowance',
    label: 'Other Allowance',
  },
  {
    value: 'GratuityNoticePayExGratiaPayment',
    label: 'Gratuity / Notice Pay / Ex-Gratia Payment',
  },
  {
    value: 'Retirement',
    label: 'Retirement',
  },
  {
    value: 'BenefitFrom1993',
    label: 'Benefit from 1993',
  },
  {
    value: 'BenefitsInKind',
    label: 'Benefits-in-Kind',
  },
  {
    value: 'RetirementBenefit',
    label: 'Retirement Benefit',
  },
  {
    value: 'NotTaxable',
    label: 'Not Taxable',
  },
];

export const PayItemCategories = [
  {
    value: 'Addition',
    label: 'Addition',
  },
  {
    value: 'Deduction',
    label: 'Deduction',
  },
  {
    value: 'Reimbursement',
    label: 'Reimbursement',
  },
  {
    value: 'Overtime',
    label: 'Overtime',
  },
  {
    value: 'Salary',
    label: 'Salary',
  },
];

export const CPFTypes = [
  {
    value: 'OrdinaryWages',
    label: 'Ordinary Wages',
  },
  {
    value: 'AdditionalWages',
    label: 'Additional Wages',
  },
  {
    value: 'NoWages',
    label: 'No Wages',
  },
];
export const CPFTypesShort = {
  OrdinaryWages: 'OW',
  AdditionalWages: 'AW',
  NoWages: 'NW',
};

export const PayItemUnits = [
  {
    value: 'Amount',
    label: 'Amount',
  },
  {
    value: 'Percentage',
    label: 'Percentage',
  },
];

export const PayItemAttendanceConditionTypes = [
  {
    value: 'AttendancePresence',
    label: 'Attendance Presence',
  },
  {
    value: 'AfterWorkingHours',
    label: 'After Working Hours',
  },
  {
    value: 'PunctualityTimeIn',
    label: 'Punctuality (Time In)',
  },
  {
    value: 'PunctualityTimeOut',
    label: 'Punctuality (Time Out)',
  },
];

export const PayItemAttendanceConditionPerdayOrAttendance = [
  {
    value: 'Perday',
    label: 'Per day',
  },
  {
    value: 'Attendance',
    label: 'Attendance',
  },
];

export const PayItemAttendanceConditionDayOrMonths = [
  {
    value: 'Day',
    label: 'Day',
  },
  {
    value: 'Month',
    label: 'Month',
  },
];

export const PayItemAttendanceConditionFrequencyTimeIn = [
  {
    value: 'LateLessThan',
    label: 'Lates Less than',
  },
  {
    value: 'LateMoreThan',
    label: 'Late More than',
  },
];

export const PayItemAttendanceConditionFrequencyTimeOut = [
  {
    value: 'NeverEarlyMoreThan',
    label: 'Never Early Out More than',
  },
  {
    value: 'NeverEarlyLessThan',
    label: 'Never Early Out Less than',
  },
];

export const PaySchemeTemplateTypes = [
  {
    value: 'Monthly',
    label: 'Monthly',
  },
  {
    value: 'Hourly',
    label: 'Hourly',
  },
  {
    value: 'Daily',
    label: 'Daily',
  },
];

export const HightestEducatonOptions = [
  { label: 'Never Attended School', id: 'Never Attended School' },
  { label: 'Pre-primary/Kindergarten', id: 'Pre-primary/Kindergarten' },
  {
    label: 'Primary School without PSLE Qualification or equivalent',
    id: 'Primary School without PSLE Qualification or equivalent',
  },
  {
    label: 'Primary School Leaving Certificate(PSLE or equivalent)',
    id: 'Primary School Leaving Certificate(PSLE or equivalent)',
  },
  {
    label: 'Secondary School education without  a GCE ‘O’/’N’ Level or equivalent',
    id: 'Secondary School education without  a GCE ‘O’/’N’ Level or equivalent',
  },
  { label: 'National ITE Certificate or equivalent', id: 'National ITE Certificate or equivalent' },
  { label: 'ITE Skills Certificate (ISC) or equivalent', id: 'ITE Skills Certificate (ISC) or equivalent' },
  { label: 'Higher NITEC  or equivalent', id: 'Higher NITEC  or equivalent' },
  { label: 'Master NITEC or equivalent', id: 'Master NITEC or equivalent' },
  { label: 'WSQ Certificate or equivalent', id: 'WSQ Certificate or equivalent' },
  { label: 'WSQ Higher Certificate or equivalent', id: 'WSQ Higher Certificate or equivalent' },
  { label: 'WSQ Advanced Certificate or equivalent', id: 'WSQ Advanced Certificate or equivalent' },
  { label: 'Polytechnic Diploma or equivalent', id: 'Polytechnic Diploma or equivalent' },
  { label: 'Polytechnic Advanced Diploma or equivalent', id: 'Polytechnic Advanced Diploma or equivalent' },
  { label: 'ITE Diploma or equivalent', id: 'ITE Diploma or equivalent' },
  { label: 'WSQ Diploma', id: 'WSQ Diploma' },
  { label: 'Bachelor’s degree or equivalent', id: 'Bachelor’s degree or equivalent' },
  { label: 'Postgraduate diploma', id: 'Postgraduate diploma' },
  { label: 'WSQ graduate certificate', id: 'WSQ graduate certificate' },
  { label: 'WSQ graduate Diploma', id: 'WSQ graduate Diploma' },
  { label: 'Master’s degree or equivalent', id: 'Master’s degree or equivalent' },
  { label: 'Doctorate or equivalent', id: 'Doctorate or equivalent' },
];

export const Timezones = [
  {
    label: '(GMT+02:00) Cairo, Istanbul, Kyiv, Minsk',
    id: 'GMT_02_00',
  },
  {
    label: '(GMT+03:00) Kuwait, Moscow, Volgograd, Nairobi,',
    id: 'GMT_03_00',
  },
  {
    label: '(GMT+04:00) Abu Dhabi, Muscat, Baku, Yerevan',
    id: 'GMT_04_00',
  },
  {
    label: '(GMT+04:30) Kabul',
    id: 'GMT_04_030',
  },
  {
    label: '(GMT+05:00) Islamabad, Karachi, Tashkent, Yekaterinburg',
    id: 'GMT_05_00',
  },
  {
    label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    id: 'GMT_05_30',
  },
  {
    label: '(GMT+05:45) Kathmandu',
    id: 'GMT_05_45',
  },
  {
    label: '(GMT+06:00) Almaty, Novosibirsk, Astana, Dhaka',
    id: 'GMT_06_00',
  },
  {
    label: '(GMT+06:30) Yangon (Rangoon)',
    id: 'GMT_06_30',
  },
  {
    label: '(GMT+07:00) Bangkok, Hanoi, Jakarta, Krasnoyarsk',
    id: 'GMT_07_00',
  },
  {
    label: '(GMT+08:00) Kuala Lumpur, Singapore, Beijing, Perth',
    id: 'GMT_08_00',
  },
  {
    label: '(GMT+09:00) Osaka,Tokyo, Seoul, Yakutsk',
    id: 'GMT_09_00',
  },
  {
    label: '(GMT+09:30) Adelaide, Darwin',
    id: 'GMT_09_30',
  },
  {
    label: '(GMT+10:00) Canberra, Melbourne, Sydney, Guam    ',
    id: 'GMT_10_00',
  },
  {
    label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    id: 'GMT_11_00',
  },
  {
    label: '(GMT+12:00) Fiji, Kamchatka, Auckland, Wellington',
    id: 'GMT_12_00',
  },
  { label: "(GMT+13:00) Nuku'alofa", id: 'GMT_13_00' },
];

export const WorkingDayOvertimeRateType = [
  'SameAsBaseHourlyRate',
  '1.5xOfBaseHourlyRate',
  '2xOfBaseHourlyRate',
  '2.5xOfBaseHourlyRate',
  '3xOfBaseHourlyRate',
  'CustomAmount',
];

export const WorkingDayOvertimeRateList = [
  {
    value: 'SameAsBaseHourlyRate',
    label: 'Same as Base Hourly Rate',
  },
  {
    value: '1.5xOfBaseHourlyRate',
    label: '1.5x of Base Hourly Rate',
  },
  {
    value: '2xOfBaseHourlyRate',
    label: '2x of Base Hourly Rate',
  },
  {
    value: '2.5xOfBaseHourlyRate',
    label: '2.5x of Base Hourly Rate',
  },
  {
    value: '3xOfBaseHourlyRate',
    label: '3x of Base Hourly Rate',
  },
  {
    value: 'CustomAmount',
    label: 'Custom Amount',
  },
];

export const OvertimePeriodBegins = [
  {
    value: 'AfterWorkingHours',
    label: 'After Working Hours',
  },
  {
    value: 'AfterFixedHours',
    label: 'After Fixed Hours',
  },
];

export const STATUS = {
  'Pending Employee': {
    text: 'Pending Employee',
    className: 'option-Cancelled t-font-bolder',
  },
  'Pending Reviewer': {
    text: 'Pending Reviewer',
    className: 'option-Pending t-font-bolder',
  },
  Completed: {
    text: 'Completed',
    className: 'option-Approved t-font-bolder',
  },
};

export const REGEX_NUMBER = /^(\s*|\d+)$/;
export const REGEX_NUMBER_DECIMAL = /^[0-9.,]+$/;
export const REGEX_NUMBER_WITH_TWO_DECIMAL = /^\d+(?:\.\d{1,2})?$/;
export const REGEX_PASSWORD = /^(?=.{1,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/;

export const ForeignerType = [
  {
    value: 'PermanentResident',
    label: 'Permanent Resident',
  },
  {
    value: 'WorkPermit',
    label: 'Work Permit',
  },
  {
    value: 'SPass',
    label: 'SPass',
  },
  {
    value: 'EmploymentPass',
    label: 'Employment Pass',
  },
];

export const WorkPermitForeignerType = ['WorkPermit', 'SPass', 'EmploymentPass'];

export const workHourTypes = {
  fixed: 'fixed',
  shiftPlanning: 'shiftPlanning',
};

export const workTimeTypes = {
  fixed: 'fixed',
  flexible: 'flexible',
};

export const shiftPlanningType = {
  WorkingDay: 'Working_Day',
  OffDay: 'Off_Day',
  RestDay: 'Rest_Day',
  OnLeave: 'On_leave',
};

export const WEEK_BOOKING = [
  {
    timeStart: 'mondayStartTime',
    timeEnd: 'mondayEndTime',
    check: 'isSelectedMonday',
    day: 'Monday',
  },
  {
    timeStart: 'tuesdayStartTime',
    timeEnd: 'tuesdayEndTime',
    check: 'isSelectedTuesday',
    day: 'Tuesday',
  },
  {
    timeStart: 'wednesdayStartTime',
    timeEnd: 'wednesdayEndTime',
    check: 'isSelectedWednesday',
    day: 'Wednesday',
  },
  {
    timeStart: 'thurdayStartTime',
    timeEnd: 'thurdayEndTime',
    check: 'isSelectedThursday',
    day: 'Thursday',
  },
  {
    timeStart: 'fridayStartTime',
    timeEnd: 'fridayEndTime',
    check: 'isSelectedFriday',
    day: 'Friday',
  },
  {
    timeStart: 'saturdayStartTime',
    timeEnd: 'saturdayEndTime',
    check: 'isSelectedSaturday',
    day: 'Saturday',
  },
  {
    timeStart: 'sundayStartTime',
    timeEnd: 'sundayEndTime',
    check: 'isSelectedSunday',
    day: 'Sunday',
  },
];

export const MONTH_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const DETAIL_TITLE = {
  employeeName: 'Employee Name',
  employeeNumber: 'Employee #',
  dateOfBirth: 'Date of Birth',
  age: 'Age',
  hiredDate: 'Hired Date',
  yearOfService: 'Year Of Service',
  countryOfWork: 'Country of Work',
  organization: 'Organization',
  department: 'Department',
  roleTitle: 'Role Title',
};

export const DETAIL_COMP = {
  employeeName: 'textBox',
  employeeNumber: 'textBox',
  dateOfBirth: 'date',
  age: 'textBox',
  hiredDate: 'date',
  yearOfService: 'textBox',
  countryOfWork: 'select',
  organization: 'select',
  department: 'select',
  roleTitle: 'select',
};

export const EMPLOYEE_INFO = {
  employeeName: 'name',
  employeeNumber: 'indexNo',
  dateOfBirth: 'birthDay',
  age: 'age',
  hiredDate: 'employmentStartDate',
  yearOfService: 'yearOfService',
  countryOfWork: 'countryOfWork',
  organization: 'organization',
  department: 'department',
  roleTitle: 'roleTitle',
};

export const RATING = [1, 2, 3, 4, 5];

export const DeductionBreakGeneration = [
  {
    value: 0,
    label: 'Only Deduct break duration if actual working hour greater than half of standard working hour',
  },
  {
    value: 1,
    label: 'Deduct full break duration in all cases',
  },
];

export const WorkplaceTypes = {
  onsite: 'On-site',
  remote: 'Remote',
};

export const EmployeeWorkplaceTypes = {
  onsite: 'On-site',
  hybrid: 'Hybrid',
  remote: 'Remote',
};

export const CURRENCIES= [
  { country: 'Albania Lek', currency: 'ALL', symbol: 'Lek' },
  { country: 'Afghanistan Afghani', currency: 'AFN', symbol: '؋' },
  { country: 'Argentina Peso', currency: 'ARS', symbol: '$' },
  { country: 'Aruba Guilder', currency: 'AWG', symbol: 'ƒ' },
  { country: 'Australia Dollar', currency: 'AUD', symbol: '$' },
  { country: 'Azerbaijan Manat', currency: 'AZN', symbol: '₼' },
  { country: 'Bahamas Dollar', currency: 'BSD', symbol: '$' },
  { country: 'Barbados Dollar', currency: 'BBD', symbol: '$' },
  { country: 'Belarus Ruble', currency: 'BYN', symbol: 'Br' },
  { country: 'Belize Dollar', currency: 'BZD', symbol: 'BZ$' },
  { country: 'Bermuda Dollar', currency: 'BMD', symbol: '$' },
  { country: 'Bolivia Bolíviano', currency: 'BOB', symbol: '$b' },
  { country: 'Bosnia and Herzegovina Convertible Mark', currency: 'BAM', symbol: 'KM' },
  { country: 'Botswana Pula', currency: 'BWP', symbol: 'P' },
  { country: 'Bulgaria Lev', currency: 'BGN', symbol: 'лв' },
  { country: 'Brazil Real', currency: 'BRL', symbol: 'R$' },
  { country: 'Brunei Darussalam Dollar', currency: 'BND', symbol: '$' },
  { country: 'Cambodia Riel', currency: 'KHR', symbol: '៛' },
  { country: 'Canada Dollar', currency: 'CAD', symbol: '$' },
  { country: 'Cayman Islands Dollar', currency: 'KYD', symbol: '$' },
  { country: 'Chile Peso', currency: 'CLP', symbol: '$' },
  { country: 'China Yuan Renminbi', currency: 'CNY', symbol: '¥' },
  { country: 'Colombia Peso', currency: 'COP', symbol: '$' },
  { country: 'Costa Rica Colon', currency: 'CRC', symbol: '₡' },
  { country: 'Croatia Kuna', currency: 'HRK', symbol: 'kn' },
  { country: 'Cuba Peso', currency: 'CUP', symbol: '₱' },
  { country: 'Czech Republic Koruna', currency: 'CZK', symbol: 'Kč' },
  { country: 'Denmark Krone', currency: 'DKK', symbol: 'kr' },
  { country: 'Dominican Republic Peso', currency: 'DOP', symbol: 'RD$' },
  { country: 'East Caribbean Dollar', currency: 'XCD', symbol: '$' },
  { country: 'Egypt Pound', currency: 'EGP', symbol: '£' },
  { country: 'El Salvador Colon', currency: 'SVC', symbol: 'SVC' },
  { country: 'Euro', currency: 'EUR', symbol: '€' },
  { country: 'Falkland Islands (Malvinas) Pound', currency: 'FKP', symbol: '£' },
  { country: 'Fiji Dollar', currency: 'FJD', symbol: '$' },
  { country: 'Ghana Cedi', currency: 'GHS', symbol: '¢' },
  { country: 'Gibraltar Pound', currency: 'GIP', symbol: '£' },
  { country: 'Guatemala Quetzal', currency: 'GTQ', symbol: 'Q' },
  { country: 'Guernsey Pound', currency: 'GGP', symbol: '£' },
  { country: 'Guyana Dollar', currency: 'GYD', symbol: '$' },
  { country: 'Honduras Lempira', currency: 'HNL', symbol: 'L' },
  { country: 'Hong Kong Dollar', currency: 'HKD', symbol: '$' },
  { country: 'Hungary Forint', currency: 'HUF', symbol: 'Ft' },
  { country: 'Iceland Krona', currency: 'ISK', symbol: 'kr' },
  { country: 'India Rupee', currency: 'INR', symbol: '₹' },
  { country: 'Indonesia Rupiah', currency: 'IDR', symbol: 'Rp' },
  { country: 'Iran Rial', currency: 'IRR', symbol: '﷼' },
  { country: 'Isle of Man Pound', currency: 'IMP', symbol: '£' },
  { country: 'Israel Shekel', currency: 'ILS', symbol: '₪' },
  { country: 'Jamaica Dollar', currency: 'JMD', symbol: 'J$' },
  { country: 'Japan Yen', currency: 'JPY', symbol: '¥' },
  { country: 'Jersey Pound', currency: 'JEP', symbol: '£' },
  { country: 'Kazakhstan Tenge', currency: 'KZT', symbol: 'лв' },
  { country: 'South Korean Won', currency: 'KPW', symbol: '₩' },
  { country: 'Kyrgyzstan Som', currency: 'KGS', symbol: 'лв' },
  { country: 'Laos Kip', currency: 'LAK', symbol: '₭' },
  { country: 'Lebanon Pound', currency: 'LBP', symbol: '£' },
  { country: 'Liberia Dollar', currency: 'LRD', symbol: '$' },
  { country: 'Macedonia Denar', currency: 'MKD', symbol: 'ден' },
  { country: 'Malaysia Ringgit', currency: 'MYR', symbol: 'RM' },
  { country: 'Mauritius Rupee', currency: 'MUR', symbol: '₨' },
  { country: 'Mexico Peso', currency: 'MXN', symbol: '$' },
  { country: 'Mongolia Tughrik', currency: 'MNT', symbol: '₮' },
  { country: 'Moroccan-dirham', currency: 'MAD', symbol: 'د.إ' },
  { country: 'Mozambique Metical', currency: 'MZN', symbol: 'MT' },
  { country: 'Namibia Dollar', currency: 'NAD', symbol: '$' },
  { country: 'Nepal Rupee', currency: 'NPR', symbol: '₨' },
  { country: 'Netherlands Antilles Guilder', currency: 'ANG', symbol: 'ƒ' },
  { country: 'New Zealand Dollar', currency: 'NZD', symbol: '$' },
  { country: 'Nicaragua Cordoba', currency: 'NIO', symbol: 'C$' },
  { country: 'Nigeria Naira', currency: 'NGN', symbol: '₦' },
  { country: 'Norway Krone', currency: 'NOK', symbol: 'kr' },
  { country: 'Oman Rial', currency: 'OMR', symbol: '﷼' },
  { country: 'Pakistan Rupee', currency: 'PKR', symbol: '₨' },
  { country: 'Panama Balboa', currency: 'PAB', symbol: 'B/.' },
  { country: 'Paraguay Guarani', currency: 'PYG', symbol: 'Gs' },
  { country: 'Peru Sol', currency: 'PEN', symbol: 'S/.' },
  { country: 'Philippines Peso', currency: 'PHP', symbol: '₱' },
  { country: 'Poland Zloty', currency: 'PLN', symbol: 'zł' },
  { country: 'Qatar Riyal', currency: 'QAR', symbol: 'ر.ق' },
  { country: 'Romania Leu', currency: 'RON', symbol: 'lei' },
  { country: 'Saint Helena Pound', currency: 'SHP', symbol: '£' },
  { country: 'Saudi Arabia Riyal', currency: 'SAR', symbol: '﷼' },
  { country: 'Serbia Dinar', currency: 'RSD', symbol: 'Дин.' },
  { country: 'Seychelles Rupee', currency: 'SCR', symbol: '₨' },
  { country: 'Singapore Dollar', currency: 'SGD', symbol: '$' },
  { country: 'Solomon Islands Dollar', currency: 'SBD', symbol: '$' },
  { country: 'Somalia Shilling', currency: 'SOS', symbol: 'So' },
  { country: 'South Africa Rand', currency: 'ZAR', symbol: 'R' },
  { country: 'Sri Lanka Rupee', currency: 'LKR', symbol: '₨' },
  { country: 'Sweden Krona', currency: 'SEK', symbol: 'kr' },
  { country: 'Switzerland Franc', currency: 'CHF', symbol: 'CHF' },
  { country: 'Suriname Dollar', currency: 'SRD', symbol: '$' },
  { country: 'Syria Pound', currency: 'SYP', symbol: '£' },
  { country: 'Taiwan New Dollar', currency: 'TWD', symbol: 'NT$' },
  { country: 'Thailand Baht', currency: 'THB', symbol: '฿' },
  { country: 'Trinidad and Tobago Dollar', currency: 'TTD', symbol: 'TT$' },
  { country: 'Turkey Lira', currency: 'TRY', symbol: '₺' },
  { country: 'Tuvalu Dollar', currency: 'TVD', symbol: '$' },
  { country: 'Ukraine Hryvnia', currency: 'UAH', symbol: '₴' },
  { country: 'UAE-Dirham', currency: 'AED', symbol: 'د.إ' },
  { country: 'United Kingdom Pound', currency: 'GBP', symbol: '£' },
  { country: 'United States Dollar', currency: 'USD', symbol: '$' },
  { country: 'Uruguay Peso', currency: 'UYU', symbol: '$U' },
  { country: 'Uzbekistan Som', currency: 'UZS', symbol: 'лв' },
  { country: 'Venezuela Bolívar', currency: 'VEF', symbol: 'Bs' },
  { country: 'Viet Nam Dong', currency: 'VND', symbol: '₫' },
  { country: 'Yemen Rial', currency: 'YER', symbol: '﷼' },
  { country: 'Zimbabwe Dollar', currency: 'ZWD', symbol: 'Z$1' }
];
 