import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import EventPopover from './EventPopover';
import { useParams } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { setEditEventRoomDataToReducer } from 'app/redux/actions/RoomBookingActions';

const CustomEvent = ({
  url,
  event,
  updateRoom,
  handleChangeDate,
  clearEvent,
  view,
  employee,
  rooms,
  refreshData,
  allEvents,
  setEvents,
  isEdit,
  resetOldEvents,
  showModal,
  setShowModal,
  // setEditEventRoomDataToReducer
}) => {
  const { id: bookingId } = useParams();
  const ref = useRef();
  const [showPopover, setShowPopover] = useState(false);

  const handleCloseCustomEvent = (isUpdated = false) => {
    setShowPopover(false);
    if (!event.event.isUpdate) {
      clearEvent();
    } else if (isUpdated) {
      // refreshData();
    }
  };

  // useEffect(() => {
  //   if (isEdit && bookingId === event.event.id) {
  //     setEditEventRoomDataToReducer({url, event});
  //   }
  // }, []);

  useEffect(() => {
    if (event.event.isNew || event?.event?.isShow) {
      if (ref.current) {
        ref.current.click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popoverItem = (event) => {
    return (
      <Popover
        id={`popover-basic popover-basic${event.event.id}`}
        title={event.title}
        style={{
          maxWidth: isEdit ? '581px' : '448px',
          height: isEdit ? '' : '187px',
          padding: '20px',
          gap: '20px',
          zIndex: 999,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <EventPopover
          url={url}
          refreshData={refreshData}
          employee={employee}
          rooms={rooms}
          setShowModal={(id) => {
            if (isEdit) {
              setShowPopover(false);
            }
          }}
          handleChangeDate={handleChangeDate}
          view={view}
          event={event}
          updateRoom={updateRoom}
          closePopover={handleCloseCustomEvent}
          allEvents={allEvents}
          setEvents={setEvents}
          isEdit={isEdit}
        />
      </Popover>
    );
  };

  const editItem = (event) => {
    let clsName = 'edit-event';
    if ((isEdit && event.event.isNew) || (!isEdit && event.event.isEdit)) {
      clsName = 'new-event';
    }
    return (
      <OverlayTrigger
        show={showPopover}
        onToggle={(show) => setShowPopover(show)}
        id={event.event.id}
        trigger={['click']}
        placement="auto"
        rootClose
        // onEnter={() => resetOldEvents(event)}
        children={ref}
        overlay={popoverItem(event)}
      >
        <div
          ref={ref}
          id={`new-event-${event.event.id}-overlayTrigger`}
          className={clsName}
          style={{ width: '100%', height: '100%' }}
        >
          <div>{event.event.employee.name}</div>
          <div style={{ fontSize: '80%', marginTop: '5px' }}>
            {moment(event.event.start).format('hh:mm A')} - {moment(event.event.end).format('hh:mm A')}
          </div>
        </div>
      </OverlayTrigger>
    );
  };

  // const renderModalEvent = useMemo(() => {
  //   if (showModal.show && showModal.id === event.event.id) {
  //     return (
  //       <EventModal
  //         url={url}
  //         refreshData={refreshData}
  //         employee={employee}
  //         rooms={rooms}
  //         handleChangeDate={handleChangeDate}
  //         view={view}
  //         event={event}
  //         updateRoom={updateRoom}
  //         closePopover={() => {
  //           setShowModal({ show: false, id: null });
  //         }}
  //         allEvents={allEvents}
  //         setEvents={setEvents}
  //         isShow={showModal.show}
  //         isEdit={isEdit}
  //       />
  //     );
  //   } else return undefined;
  // }, [showModal, event]);

  const newItem = (event) => {
    let isShow = showPopover;
    if (!isEdit && event.event.isNew) {
      isShow = true;
    }
    return (
      <OverlayTrigger
        show={isShow}
        onToggle={(show) => setShowPopover(show)}
        id={event.event.id}
        trigger={['click']}
        placement="auto"
        rootClose
        children={ref}
        overlay={popoverItem(event)}
      >
        <div
          ref={ref}
          id={`new-event-${event.event.id}-overlayTrigger`}
          className="new-event"
          style={{ width: '100%', height: '100%' }}
        >
          <div>{event.event.employee.name}</div>
          <div style={{ fontSize: '80%', marginTop: '5px' }}>
            {moment(event.event.start).format('hh:mm A')} - {moment(event.event.end).format('hh:mm A')}
          </div>
        </div>
      </OverlayTrigger>
    );
  };
  return (
    <>
      {event.event.isEdit ? (
        editItem(event)
      ) : event.event.isNew ? (
        newItem(event)
      ) : (
        <>
          <div className="old-event" style={{ width: '100%', height: '100%' }}>
            <div>{event.event.employee.name}</div>
            <div style={{ fontSize: '80%', marginTop: '5px' }}>
              {moment(event.event.start).format('hh:mm A')} - {moment(event.event.end).format('hh:mm A')}
            </div>
          </div>
        </>
      )}
      {/* {renderModalEvent} */}
      {/* {showModal?.show && event.event.id === showModal.id  && <EventModal
        url={url}
        refreshData={refreshData}
        employee={employee}
        rooms={rooms}
        handleChangeDate={handleChangeDate}
        view={view}
        event={event}
        updateRoom={updateRoom}
        closePopover={() => {
          setShowModal({show: false, id: null});
        }}
        allEvents={allEvents}
        setEvents={setEvents}
        isShow={true}
        isEdit={isEdit}
      />} */}
    </>
  );
};

export default CustomEvent;
// const mapActionToProps = (dispatch) => ({
//   // setNewEventRoomDataToReducer: (newEvents) => dispatch(setNewEventRoomDataToReducer(newEvents)),
//   setEditEventRoomDataToReducer: (editEvents) => dispatch(setEditEventRoomDataToReducer(editEvents)),
// });

// export default connect(undefined, mapActionToProps)(CustomEvent);
