import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AdPayDialog = ({ open, closeDialog, payItems, addPayItem = () => null }) => {
  const [selected, setSelected] = useState();

  const onAdd = () => {
    const item = payItems.find((p) => p?.id === selected);
    addPayItem(item);
    closeDialog();
  };

  return (
    <Modal show={open} onHide={closeDialog}>
      <Modal.Body>
        <div className="px-3">
          <div className="form-group mb-4">
            <label htmlFor="shiftType">Pay Items*</label>
            <select
              id="pay-items"
              className="form-control"
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <option value="" disabled selected>
                Select Pay Items
              </option>
              {payItems.map(item => {
                if(item?.isActive){
                  return (<option key={item?.id} value={item?.id}>
                    {item?.description}
                  </option>)
                }else{
                  return (<option key={item?.id} value={item?.id} disabled>
                    {item?.description}
                  </option>)
                }
              })}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="form-actions">
          <Button className="btn btn-rounded btn-primary btn-cancel" variant="outline-primary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button className="btn btn-rounded btn-primary btn-save" disabled={!selected} onClick={onAdd}>
            Add
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AdPayDialog;
