import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import axios from 'app/services/axios';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import DateTime from 'app/components/DatePicker';
import TimeSlotEvent from './TimeSlotEvent';
import { getDateToIsoString, getTimeIsoString } from '@utils';
import Swal from 'sweetalert2';
import History from '@history';
import useGetOrgSameAdmin from 'app/hooks/useGetOrgSameAdmin';
import { getGMTByTimeZone } from 'app/constants/timezone';
import { connect, useSelector } from 'react-redux';
import { setEditEventRoomDataToReducer } from 'app/redux/actions/RoomBookingActions';

const EventModal = ({ setEditEventRoomDataToReducer }) => {
  const { editData } = useSelector((state) => state.roomBooking);
  const url = editData?.url;
  const { organisations } = useGetOrgSameAdmin();
  const [branches, setBranches] = useState([]);
  const [roomsBranch, setRoomsBranch] = useState([]);
  const currentRef = useRef();

  const [showPicker, setShowPicker] = useState(false);

  const [eventItem, setEventItem] = useState({});

  const [bookingEditData, setBookingEditData] = useState({});

  useEffect(() => {
    const event = editData?.event?.event;
    if (event) {
      setEventItem({ event });
      setBookingEditData({
        organizationId: event.organizationId,
        branchOutletId: event.branch?.id,
        roomId: event?.roomId || event.room?.id,
      });
      getBranches(event.organizationId, event.branch?.id, event?.roomId || event.room?.id);
    }
  }, [JSON.stringify(editData)]);

  useEffect(() => {
    if (eventItem?.event) {
      setBookingEditData({
        organizationId: eventItem.event.organizationId,
        branchOutletId: eventItem.event.branch.id,
        roomId: eventItem?.event?.roomId || eventItem.event.room?.id,
      });
    }
  }, []);
  const dateTimeRef = useRef();

  useEffect(() => {
    if (eventItem?.event) {
      getBranches(
        eventItem.event.organizationId,
        eventItem.event.branch.id,
        eventItem?.event?.roomId || eventItem.event.room?.id
      );
    }
  }, []);

  useLayoutEffect(() => {
    if (dateTimeRef?.current) {
      const childDiv = dateTimeRef?.current.querySelector('.rdtStatic');
      if (childDiv) {
        if (childDiv?.classList?.contains('rdtStatic')) {
          childDiv.classList.remove('rdtStatic');
        }
      }
    }
  }, [showPicker]);

  const handleChangeTimeEvent = (v, event) => {
    setShowPicker(false);
    if (v.startOf('day').isSame(moment(eventItem.event.start).startOf('day'))) return;
    const date = v.set({ hour: eventItem.event.start.getHours(), minute: eventItem.event.start.getMinutes() });
    const { branchOutletId, organizationId, roomId } = bookingEditData;
    const branch = branches.find(({ id }) => id === branchOutletId);
    const room = roomsBranch.find(({ id }) => id === roomId);
    const newEvent = {
      ...eventItem,
      event: {
        ...eventItem.event,
        start: date.toDate(),
        organizationId,
        roomId,
        branchOutletId,
        branch,
        room,
        isEdit: false,
        isNew: true,
        end: date
          .clone()
          .hour(
            eventItem.event.end.getHours()
            // 'hours'
          )
          .minute(eventItem.event.end.getMinutes())
          .toDate(),
      },
    };
    setEventItem(newEvent);
  };

  const handleUpdateTimeSlot = (event) => {
    const { branchOutletId, organizationId, roomId } = bookingEditData;
    const branch = branches.find(({ id }) => id === branchOutletId);
    const room = roomsBranch.find(({ id }) => id === roomId);
    const newItem = {
      ...eventItem,
      event: {
        ...eventItem.event,
        start: event.event.start,
        end: event.event.end,
        organizationId: organizationId,
        branch,
        branchOutletId: branchOutletId,
        roomId: roomId,
        room,
      },
    };
    setEventItem(newItem);
  };

  const handleSubmit = () => {
    const roomId = eventItem?.event?.resourceId || eventItem?.event?.roomId || eventItem.event.room?.id;
    const body = {
      bookingDate: getDateToIsoString(moment(eventItem.event.start), moment(eventItem.event.start).format('HH:mm')),
      bookingTimeFrom: getTimeIsoString(moment(eventItem.event.start).format('HH:mm')),
      bookingTimeFromOrigin: moment(eventItem.event.start).format('HH:mm'),
      bookingTimeTo: getTimeIsoString(moment(eventItem.event.end).format('HH:mm')),
      bookingTimeToOrigin: moment(eventItem.event.end).format('HH:mm'),
      bookingDateFrom: getDateToIsoString(moment(eventItem.event.start), moment(eventItem.event.start).format('HH:mm')),
      bookingDateTo: getDateToIsoString(moment(eventItem.event.end), moment(eventItem.event.end).format('HH:mm')),
      bookingDateFromOrigin: moment(eventItem.event.start),
      bookingDateToOrigin: moment(eventItem.event.end),
      employeeBookedId: eventItem.event.employee.id,
      branchOutletId: eventItem.event.branch.id,
      roomId,
    };

    if (body.bookingTimeTo === '00:00') body.bookingTimeTo = '24:00';
    if (bookingEditData.organizationId) {
      body.organizationId = bookingEditData.organizationId;
    } else {
      const branch = roomsBranch.find(({ id }) => id === bookingEditData.branchOutletId);
      body.organizationId = branch.organizationId;
    }
    body.branchOutletId = bookingEditData.branchOutletId;
    body.roomId = bookingEditData.roomId;
    handleEditRoom(body);
  };

  const handleEditRoom = (body) => {
    axios
      .put('/api/v1/room-booking/' + eventItem.event.id, { ...body })
      .then(() => {
        setEditEventRoomDataToReducer(null);
        Swal.fire('Update Booking room!', 'Done', 'success').then(() => {
          History.push(url);
        });
      })
      .catch((error) => {
        const errors = (error?.response?.data?.message || '').split(':');
        const message = errors.length > 1 ? errors[1].trim() : errors[0];
        Swal.fire('Error!', message, 'error');
      });
  };

  const isValidDate = (currentDate) => {
    const today = moment().subtract('1', 'days');
    const dateTimeEvent = moment(eventItem.event.start).format('HH:mm');
    const dateTimeToday = today.format('HH:mm');
    if (currentDate.startOf('day').isSame(moment().startOf('day'))) {
      return dateTimeEvent > dateTimeToday;
    }
    return currentDate.isAfter(moment().subtract('1', 'days'));
  };

  const getRooms = async (branchId, organizationId, organizationId1, roomId) => {
    const branch = branches.find((item) => item.id === branchId);
    const orgId = organizationId || branch?.organizationId || '';
    await axios
      .get(`/api/v1/room/org/${orgId}?branchOutletId=` + branchId)
      .then((res) => {
        const { data, statusCode } = res?.data;
        if (statusCode === 200) {
          setRoomsBranch(data);
          if (data.length) {
            const newRoomId = roomId || data[0].id;
            setBookingEditData({
              ...bookingEditData,
              organizationId: orgId,
              branchOutletId: branchId,
              roomId: newRoomId,
            });
          }
        }
      })
      .catch(() => {
        setRoomsBranch([]);
      });
  };

  const getBranches = async (organizationId, branchId, roomId) => {
    const params = { organizationId };
    if (organizationId) {
      await axios.get(`/api/v1/organization/branch`, { params }).then((res) => {
        const { data, statusCode } = res?.data;
        if (statusCode === 200) {
          if (data.length) {
            const branchOutletId = branchId || data[0].id;
            getRooms(branchOutletId, organizationId, organizationId, roomId);
          }
          setBranches(data);
        }
      });
    } else {
      await axios.get(`/api/v1/organization/branch-all`, { params }).then((res) => {
        const { data, statusCode } = res?.data;
        if (statusCode === 200) {
          if (data.length) {
            const branchOutletId = branchId || data[0].id;
            getRooms(branchOutletId, data[0].organizationId, '', roomId);
          }
          setBranches(data);
        }
      });
    }
  };

  const handleChange = (field, value) => {
    const newBookingEditData = { ...bookingEditData };
    if (field === 'organizationId') newBookingEditData.branchOutletId = '';
    newBookingEditData[field] = value;
    setBookingEditData(newBookingEditData);
  };

  return (
    <Modal
      show={editData}
      centered
      className="edit-room-booking-modal"
      onHide={() => setEditEventRoomDataToReducer(null)}
    >
      {Object.values(eventItem).length && (
        <div className="event-popover-custom" ref={currentRef}>
          <div>
            <div className="t-font-boldest" style={{ fontSize: '20px', marginBottom: '20px' }}>
              {eventItem?.event?.employeeName}
            </div>
            <div className="col-12">
              <div className="form-group mb-4">
                <label htmlFor="organizationId">Organisation</label>
                <select
                  id="organizationId"
                  className="form-control"
                  name="organizationId"
                  value={bookingEditData.organizationId}
                  onChange={(e) => {
                    const organizationId = e.currentTarget.value;
                    handleChange('organizationId', organizationId);
                    getBranches(organizationId, '');
                  }}
                >
                  <option value="">All</option>
                  {organisations.map((org) => (
                    <option value={org.id} key={org.id}>
                      {org.organizationName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={`col-12`}>
              <div className="form-group mb-4">
                <label htmlFor="branchOutletId">Branch/Outlet*</label>
                <select
                  id="branchOutletId"
                  className="form-control"
                  name="branchOutletId"
                  value={bookingEditData.branchOutletId}
                  onChange={(e) => {
                    const branchId = e.currentTarget.value;
                    if (branchId) getRooms(branchId);
                    handleChange('branchOutletId', branchId);
                  }}
                >
                  {branches.map((branch) => (
                    <option value={branch.id} key={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group mb-4">
                <label htmlFor="roomId">Room*</label>
                <div className="d-flex">
                  <select
                    id="roomId"
                    className="form-control"
                    name="roomId"
                    value={bookingEditData.roomId}
                    onChange={(e) => handleChange('roomId', e.currentTarget.value)}
                  >
                    {roomsBranch.map((room) => (
                      <option value={room.id} key={room.id}>
                        {room.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row date-time-edit col-12">
              <div className="input-item col-5">
                <label>Date*</label>
                <DateTime
                  value={eventItem.event.start}
                  name="start"
                  dateFormat="dddd, DD MMM YYYY"
                  isValidDate={isValidDate}
                  onChange={(v) => handleChangeTimeEvent(v)}
                />
              </div>
              <div className="date-time time input-item col-4">
                <label>Time*</label>
                <div>
                  <TimeSlotEvent event={eventItem} isModal={true} handleUpdateTimeSlot={handleUpdateTimeSlot} />
                </div>
              </div>
              <div style={{ paddingRight: '0px' }} className="col-3">
                <label>Timezone*</label>
                <input
                  value={getGMTByTimeZone(eventItem?.event?.employeeTimezone)}
                  className="form-control form-control-rounded"
                  name="timezone"
                  disabled
                />
              </div>
            </div>
            <div className="button-wrapper" style={{ justifyContent: 'end', marginTop: '40px' }}>
              <Button className="btn-rounded btn-outline-primary" onClick={() => setEditEventRoomDataToReducer(null)}>
                Cancel
              </Button>
              <Button
                className="btn-rounded"
                onClick={handleSubmit}
                disabled={!bookingEditData.branchOutletId || !bookingEditData.roomId}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
const mapActionToProps = (dispatch) => ({
  setEditEventRoomDataToReducer: (editEvent) => dispatch(setEditEventRoomDataToReducer(editEvent)),
});

export default connect(undefined, mapActionToProps)(EventModal);
